<template>
  <a-modal
    :title="'修改' + (model ? model.username : '用户') + '所属用户组'"
    :width="640"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-transfer
        v-if="model"
        :data-source="roles"
        :titles="['所有用户组', '已选用户组']"
        :target-keys="targetKeys"
        :render="item => item.label"
        @change="handleChange"
      />
    </a-spin>
  </a-modal>
</template>

<script>
import { getUserRoleList } from '@/api/manage'

export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {
      roles: [],
      targetKeys: []
    }
  },
  mounted () {
    this.roles = []
    this.targetKeys = []
    if (this.model) {
      this.load()
    }
    this.$watch('model', () => this.load())
  },
  methods: {
    load () {
      getUserRoleList({ id: this.model.id }).then(res => {
        const allRoles = res.all
        allRoles.forEach(p => {
          p.key = p.name
          p.title = p.label
        })
        const current = res.current.map(p => p.name)
        this.roles = allRoles
        this.targetKeys = current
      })
    },
    handleChange (nextTargetKeys) {
      this.targetKeys = nextTargetKeys
    }
  }
}
</script>
