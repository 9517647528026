<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="6">
            <a-form-item label="搜索条件">
              <a-select v-model="queryType">
                <a-select-option value="username">
                  用户名
                </a-select-option>
                <a-select-option value="qq">
                  QQ
                </a-select-option>
                <a-select-option value="email">
                  邮箱
                </a-select-option>
                <a-select-option value="id">
                  用户ID
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="8">
            <a-form-item>
              <a-input v-model="query" placeholder="请输入搜索内容"/>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <span class="table-page-search-submitButtons" >
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button style="margin-left: 8px" @click="() => this.queryParam = {}">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <s-table
      ref="table"
      size="default"
      :rowKey="(record) => record.id"
      :columns="columns"
      :data="loadData"
    >
      <span slot="masterCardSlot" slot-scope="text, record">
        <router-link v-if='record.masterCard' :to="{name: 'Aime List', query: {aimeId: record.masterCard }}">AimeID: {{ record.masterCard }}</router-link>
      </span>
      <span slot="cardCountSlot" slot-scope="text, record">
        <router-link :to="{name: 'Aime List', query: {userId: record.id }}">{{ record.cardCount }}</router-link>
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a v-if="$auth('ROLE_PERMISSION_USER_PASSWORD')" @click="handleUsername(record)">修改用户名</a>
          <a-divider type="vertical" />
          <a v-if="$auth('ROLE_PERMISSION_USER_PASSWORD')" @click="handlePassword(record)">修改密码</a>
          <a-divider type="vertical" />
          <a v-if="$auth('ROLE_PERMISSION_ROLE')" @click="handleRole(record)">用户组</a>
        </template>
      </span>
    </s-table>
    <user-role-form
      ref="roleModal"
      :visible="roleFormVisible"
      :loading="confirmLoading"
      :model="formModel"
      @cancel="handleCancel"
      @ok="handleRoleSave"
    />
    <user-password-form
      ref="passwordModal"
      :visible="passwordFormVisible"
      :loading="confirmLoading"
      :model="passwordFormModel"
      @cancel="handlePasswordCancel"
      @ok="handlePasswordSave"
    />
    <user-name-form
      ref="usernameModal"
      :visible="usernameFormVisible"
      :loading="confirmLoading"
      :model="usernameFormModel"
      @cancel="handleUsernameCancel"
      @ok="handleUsernameSave"
    />
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { getUserList, saveUserName, saveUserPassword, saveUserRole } from '@/api/manage'
import UserRoleForm from '@/views/management/user/modules/UserRoleForm'
import UserPasswordForm from '@/views/management/user/modules/UserPasswordForm'
import UserNameForm from '@/views/management/user/modules/UserNameForm'

export default {
  name: 'UserList',
  components: {
    UserNameForm,
    UserPasswordForm,
    UserRoleForm,
    STable
  },
  created () {
    this.routeUpdate(this.$route.query)
  },
  beforeRouteUpdate (to, from, next) {
    next()
    this.routeUpdate(to.query)
    this.$refs.table.refresh()
  },
  data () {
    return {
      roleFormVisible: false,
      passwordFormVisible: false,
      usernameFormVisible: false,
      confirmLoading: false,
      formModel: null,
      passwordFormModel: null,
      usernameFormModel: null,
      queryType: 'username',
      query: '',
      loadData: parameter => {
        return getUserList(Object.assign(parameter, { queryType: this.queryType, query: this.query }))
      },
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          sorter: true
        },
        {
          title: '用户名',
          dataIndex: 'username',
          sorter: true
        },
        {
          title: '邮箱',
          dataIndex: 'email',
          sorter: true
        },
        {
          title: 'QQ',
          dataIndex: 'qq',
          sorter: true
        },
        {
          title: '注册IP',
          dataIndex: 'registerIp',
          sorter: true
        },
        {
          title: '注册时间',
          dataIndex: 'registerTime',
          sorter: true
        },
        {
          title: '主卡ID',
          dataIndex: 'masterCard',
          scopedSlots: { customRender: 'masterCardSlot' }
        },
        {
          title: '绑定卡片数',
          dataIndex: 'cardCount',
          scopedSlots: { customRender: 'cardCountSlot' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  methods: {
    routeUpdate (query) {
      if (query.userId) {
        this.queryType = 'id'
        this.query = `${query.userId}`
        return
      }
      this.queryType = 'username'
      this.query = ''
    },
    handleRole (record) {
      this.formModel = record
      this.roleFormVisible = true
    },
    handleRoleSave () {
      saveUserRole({ id: this.$refs.roleModal.model.id, roles: this.$refs.roleModal.targetKeys })
        .then(() => {
          this.roleFormVisible = false
          this.$message.success('修改成功')
        })
    },
    handleCancel () {
      this.roleFormVisible = false
    },
    handlePassword (record) {
      this.passwordFormModel = { id: record.id, password: '' }
      this.passwordFormVisible = true
    },
    handlePasswordSave () {
      this.$refs.passwordModal.form.validateFields((errors, values) => {
        saveUserPassword(values)
          .then(() => {
            this.passwordFormVisible = false
            this.$message.success('修改成功')
          })
      })
    },
    handlePasswordCancel () {
      this.usernameFormVisible = false
    },
    handleUsername (record) {
      this.usernameFormModel = { id: record.id, username: record.username }
      this.usernameFormVisible = true
    },
    handleUsernameSave () {
      this.$refs.usernameModal.form.validateFields((errors, values) => {
        saveUserName(values)
          .then(() => {
            this.usernameFormVisible = false
            this.$message.success('修改成功')
          })
      })
    },
    handleUsernameCancel () {
      this.usernameFormVisible = false
    }
  }
}
</script>

<style scoped>

</style>
